<template lang="pug">
    v-container.grid-list-xl.register
        loader(v-if="$root.loader || preload")
        template(v-else)
            v-snackbar(
                :timeout="3000"
                :top="true"
                :right="true"
                color="error"
                v-model="error")
                    | {{ errorText }}
                    v-btn(flat, dark, @click.native="error = false") {{ $t('cerrar') }}
            .register-poster
                v-layout(register-poster row wrap)
                    v-flex(xs11)
                        figure
                            img(:src="shareurl" alt="Wappid" @click="$router.go()")
            .register-content(:class="{'d-block': !hasSurvey}")
                v-stepper(v-if="hasSurvey" v-model="register")
                    v-stepper-header
                        v-stepper-step(step="1")
                    v-stepper-items
                        v-stepper-content(step="1")
                            br
                            br
                            h2.mt-4.secondary--text {{ currentSurvey.name }}
                            v-layout(v-if="!finished" row wrap)
                                v-flex(xs12)
                                    h3.secondary--text {{ $t('Completa la encuesta de la red')}} {{ this.currentNetwork.name }}
                                v-flex(xs12)
                                    p {{ currentSurvey.starttext }}
                                v-flex(xs12 v-for="(c, i) in customSurvey" :key="'cs'+i")
                                    p(v-if="c.description != null && c.description.toString().trim().length") {{ c.description }}
                                    .image-desc(v-if="c.hasOwnProperty('image') && c.image != null && c.image.toString().length")
                                        img(:src="c.image.toString().includes('data:image') ? c.image.toString() : (uploadurl + c.image.toString())")
                                    v-text-field(v-if="c.type == 1" v-model="survey[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                    v-text-field(multi-line v-if="c.type == 5" v-model="survey[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                    template(v-if="c.type == 2")
                                        label.d-block.mb-1 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                        v-checkbox(v-for="(opt, i) in c.options" :key="'opt'+i" v-model="survey[c.uuid]" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)" @change="limitMutipleSelection(survey[c.uuid], c)")
                                    template(v-if="c.type == 4")
                                        v-select(clearable v-if="c.depends == null ? true : !c.depends.length" v-model="survey[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="c.required" @input="computedDepends(c, $event, 'customSurvey')")
                                        v-select(clearable v-else v-model="survey[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event, 'customSurvey')")
                                    v-radio-group(v-if="c.type == 3" v-model="survey[c.uuid]")
                                        label {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                        v-radio(v-for="(opt, i) in c.options" :key="'opt2'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                                    //-Selección de imagen
                                    template(v-if="c.type == 6 && (c.depends == null ? true : !c.depends.length)")
                                        label.d-block.mt-3 
                                            | {{ c.placeholder }}
                                            span.red--text(v-if="c.required") *
                                        div.option-check(v-for="(option, i) in c.options" :key="'opt3'+i")
                                            small {{ option.option }}
                                            .check-img(@click="toogleActive(c, c.options, option, c)" :class="{'active': option.active}" style="width:150px")
                                                img(:src="option.preview.includes('data:image') ? option.preview : (uploadurl + option.preview)")
                                            small {{ option.description }}
                                    template(v-if="c.type == 6 && !(c.depends == null ? true : !c.depends.length)")
                                        label.d-block.mt-3
                                            | {{ c.placeholder }}
                                            span.red--text(v-if="c.required") *
                                        div.option-check(v-for="(option, i) in c.hasOwnProperty('coptions') ? c.coptions : []" :key="'opt3'+i")
                                            small {{ option.option }}
                                            .check-img(@click="toogleActive(c, c.coptions, option, c)" :class="{'active': option.active}" style="width:150px")
                                                img(:src="option.preview.includes('data:image') ? option.preview : (uploadurl + option.preview)")
                                            small {{ option.description }}
                                    
                                    //Escala
                                    template(v-if="c.type == 7")
                                        label.d-block.mt-3 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                        .likert-scale-group(v-if="c.config.is_rank")
                                            template(v-if="c.config.hasOwnProperty('enableAbText') && c.config.enableAbText")
                                                span.mr-2(v-if="c.config.before.toString().trim().length") {{ c.config.before }}
                                            v-radio-group.likert-scale(v-model="survey[c.uuid]" :style="{maxWidth: (c.config.maxWidth <= 0 ? 'initial': c.config.maxWidth+'px')}")
                                                v-radio(v-for="num in range(c.config.min, c.config.max)" :value="num" :label="num.toString()")
                                            template(v-if="c.config.hasOwnProperty('enableAbText') && c.config.enableAbText")
                                                span.ml-2(v-if="c.config.after.toString().trim().length") {{ c.config.after }}
                                        v-radio-group.likert-scale(v-else v-model="survey[c.uuid]" :style="{maxWidth: (c.config.maxWidth <= 0 ? 'initial': c.config.maxWidth+'px')}")
                                            v-radio(v-for="opt in c.options" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                                            
                                v-flex(xs12)
                                    p {{ currentSurvey.endtext }}
                                
                                // Confirmar WhatsApp
                                v-flex(xs12 v-if="currentSurvey.wpconfirm")
                                    h3.text-xs-center {{ $t('YA PERTENECES A NUESTRA RED') }}
                                    p.text-xs-center {{ $t('Solo falta un paso')}}
                                    vue-recaptcha.full-width.d-flex.justify-center.my-2.text-xs-center(v-if="Object.keys(currentSurvey).length && currentSurvey.recaptcha" :sitekey="captchaKey" ref="recaptcha" @verify="recaptchaVerifiy")
                                    .btns.btns-center
                                        v-btn(color="primary" :disabled="loadSurvey" :loading="loadSurvey" depressed v-if="loadSurvey ? (!loadSurveyWithRegister) : true" @click="saveSurvey(false, 'loadSurveyWithoutRegister', true)") {{ $t('Confirmar WhatsApp') }}
                                v-flex(xs12 v-else)
                                    h3.text-xs-center.my-4(v-if="!isProfile") 
                                        | {{ $t('¿Deseas recibir los resultados de la encuesta a tu WhatsApp?') }} 
                                    vue-recaptcha.full-width.d-flex.justify-center.my-2.text-xs-center(v-if="Object.keys(currentSurvey).length && currentSurvey.recaptcha" :sitekey="captchaKey" ref="recaptcha" @verify="recaptchaVerifiy")
                                    .btns.btns-center(v-if="!isRegister && !isProfile")
                                        v-btn(small color="primary" :disabled="loadSurvey" :loading="loadSurvey" depressed v-if="loadSurvey ? (!loadSurveyWithRegister) : true" @click="saveSurvey(false, 'loadSurveyWithoutRegister')") {{ $t('No deseo') }}
                                        v-btn(small color="primary" :disabled="loadSurvey" :loading="loadSurvey" depressed v-if="loadSurvey ? (!loadSurveyWithoutRegister) : true" @click="saveSurvey(true, 'loadSurveyWithRegister')") {{ $t('Si deseo') }}
                                    .btns.btns-center(v-if="!isRegister && isProfile")
                                        v-btn(small color="primary" :disabled="loadSurvey" :loading="loadSurvey" depressed @click="saveSurvey(false, 'loadSurvey')") {{ $t('Completar encuesta') }}
                            template(v-if="finished && !isRegister")
                                v-divider.x1.mt-4.mb-2.primary
                                v-card
                                    v-card-text
                                        h2.my-2.secondary--text.text-xs-center {{ $t('Gracias por finalizar la encuesta, ahora la puedes compartir') }}
                                        p.text-xs-center {{ $t('Comparte esta encuesta a través de tus redes sociales') }}
                                        .btns.btns-center
                                            v-btn.btn-whatsapp(@click.native="shareWp" large color="secondary light--text") 
                                                img(src="/public/img/icons/whatsapp-icon.svg" :alt="$t('Whatsapp')")
                                                | {{ $t('Compartir en whatsapp') }}
                                            v-btn.btn-facebook(@click.native="shareFb" small color="secondary light--text") 
                                                img(src="/public/img/icons/facebook-icon.svg" :alt="$t('Facebook')")
                                                | {{ $t('Compartir en facebook') }}
                                            v-btn.btn-twitter(@click.native="shareTw" small color="secondary light--text") 
                                                img(src="/public/img/icons/twitter-icon.svg" :alt="$t('Twitter')")
                                                | {{ $t('Compartir en twitter') }}
                                            br
                                            a.mt-4.d-block(target="blank" :href="surveyurl"): b {{ surveyurl }}
                                        h2.mt-5.mb-2.secondary--text.text-xs-center {{ $t('¿Desea iniciar una nueva encuesta?') }}
                                        .btns.btns-center
                                            v-btn.primary(@click="repeatSurvey()") {{ $t('Nueva encuesta') }}

                            template(v-if="isRegister")
                                div.mt-4.full-width
                                    h2 {{ $t('Confirma aquí tu WhatsApp y tus datos') }}
                                v-layout(row wrap)
                                    template(v-if="!initialData")
                                        v-flex(xs12)
                                            v-select(
                                                browser-autocomplete='new-password'
                                                v-model="step.countryid"
                                                :items='country'
                                                item-value="uuid"
                                                item-text="name" 
                                                :label='$t("País")' 
                                                append-icon="keyboard_arrow_down", 
                                                autocomplete
                                                :filter="vFilter"
                                                required
                                                @input="fetchState"
                                            )
                                        v-flex(xs12)
                                            v-text-field(:prefix="phone" v-model="step.phone" :label="$t('Celular (WhatsApp)')" type="text" required)
                                        v-flex(xs12)
                                            v-text-field(v-model="step.email" :label="$t('Correo electrónico')" type="email" required)
                                        v-flex(xs12)
                                            v-text-field(
                                                v-model="step.password" 
                                                :label="$t('Contraseña')"
                                                required
                                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                                :append-icon-cb="() => (e1 = !e1)"
                                                :type="e1 ? 'password' : 'text'")
                                            small {{$t('Digite una contraseña para esta red')}}
                                        v-divider.mt-4
                                        v-flex(xs12 align-center)
                                            v-layout.pa-3(align-center hide-details)
                                                v-checkbox(v-model="terms")
                                                p.ml-2
                                                    | {{$t('Acepto')}} 
                                                    a(@click="termsModal=true") {{$t('los términos y condiciones y las políticas de privacidad')}} 
                                                    | {{$t('de este sitio con referencia al cumplimiento de la ley general de protección datos personales para el sector público')}}.
                                        v-flex(xs12 v-if="terms")
                                            .btns.btns-right
                                                v-btn(small color="primary" depressed @click="check" :loading="loadCheck") {{ $t('Siguiente') }}
                                    v-divider.mt-4
                                    template(v-if="initialData")
                                        v-flex(xs12 v-if="isAvailable('countryid')")
                                            v-select(
                                                v-model="step.countryid"
                                                :items='country'
                                                item-value="uuid"
                                                item-text="name" 
                                                :label='$t("País")' 
                                                append-icon="keyboard_arrow_down", 
                                                autocomplete
                                                :filter="vFilter"
                                                required
                                                @input="fetchState"
                                            )
                                        v-flex(xs12)
                                            v-text-field(:prefix="phone" v-model='step.phone' :label='$t("Celular (WhatsApp)")' required)
                                        v-flex(xs12)
                                            v-text-field(v-model="step.email" :label="$t('Correo electrónico')" type="email" required)
                                        v-flex(xs12)
                                            v-text-field(
                                                v-model="step.password" 
                                                :label="$t('Contraseña')"
                                                required
                                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                                :append-icon-cb="() => (e1 = !e1)"
                                                :type="e1 ? 'password' : 'text'")
                                            small {{$t('Cree una nueva contraseña para este sitio')}}
                                        v-flex(xs12)
                                            v-text-field(v-model='step.names', :label='$t("Nombre(s)")', required)
                                        v-flex(xs12)
                                            v-text-field(v-model='step.surname', :label='$t("Primer apellido")', required)
                                        v-flex(xs12)
                                            v-text-field(v-model='step.surname2' :label='$t("Segundo apellido")')
                                        v-flex(xs12 v-if="isAvailable('stateid')")
                                            v-select(
                                                v-model="step.stateid"
                                                :items='state',
                                                item-value="uuid",
                                                item-text="name", 
                                                :label='$t("Estado/Departamento/Provincia")', 
                                                append-icon="keyboard_arrow_down", 
                                                autocomplete
                                                :filter="vFilter"
                                                required
                                                @input="fetchCity"
                                            )
                                        v-flex(xs12 v-if="isAvailable('cityid')")
                                            v-select(
                                                v-model="step.cityid"
                                                :items='city',
                                                item-value="uuid",
                                                item-text="name", 
                                                :label='$t("Ciudad/Municipio")', 
                                                append-icon="keyboard_arrow_down", 
                                                autocomplete
                                                :filter="vFilter"
                                                required
                                                @change="zonei=-1"
                                                @input="fetchZone"
                                            )
                                        template(v-if="zones.length && isAvailable('zones')")
                                            v-flex(xs12 v-for="(zone, i) in zones" :key="'zs'+i")
                                                v-select(
                                                    v-model="step.zones[i]"
                                                    :items='zone'
                                                    item-value="uuid"
                                                    item-text="name"
                                                    :label='zone[0].division'
                                                    append-icon="keyboard_arrow_down"
                                                    autocomplete
                                                    :filter="vFilter"
                                                    :required="isRequired('zones')"
                                                    @change="zonei=i"
                                                    @input="fetchZone"
                                                )
                                        v-flex(xs12 v-if="isAvailable('gender')")
                                            v-select(
                                                v-model="step.gender"
                                                :items='gender'
                                                item-value="id"
                                                item-text="text"
                                                :label='$t("Género")'
                                                append-icon="keyboard_arrow_down"
                                                autocomplete
                                                :filter="vFilter"
                                                required
                                            )
                                        v-flex(xs12 v-if="isAvailable('birthdate')")
                                            v-dialog(ref='dialogBirthdate' persistent v-model='modalBirthdate' lazy full-width width='290px' :return-value.sync='step.birthdate')
                                                v-text-field(slot='activator' :label='$t("Fecha de nacimiento")' v-model='step.birthdate' readonly hide-details)
                                                v-date-picker(
                                                    :locale="$root.currentLanguage" 
                                                    v-model='step.birthdate' 
                                                    ref="birthdate" 
                                                    scrollable
                                                    :min="minBirthdateDate"
                                                    :max="maxBirthdateDate"
                                                )
                                                    v-spacer
                                                    v-btn(flat color='primary' @click='modalBirthdate = false') {{$t('Cancelar')}}
                                                    v-btn(flat color='primary' @click='$refs.dialogBirthdate.save(step.birthdate)') {{$t('OK')}}
                                        v-flex(xs12 v-if="isAvailable('document')")
                                            v-text-field(v-model='step.document' :label='$t("Número de documento de identificación")')
                                        v-flex(xs12 v-show="false")
                                            v-text-field(v-model="step.code" :label="$t('Código de invitación')")
                                        v-flex(xs12 v-for="(c, i) in customForm" :key="'c'+i")
                                            v-text-field(v-if="c.type == 1" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                            v-text-field(multi-line v-if="c.type == 5" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                            template(v-if="c.type == 2")
                                                label.d-block.mb-1 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                                v-checkbox(v-for="(opt, i) in c.options" :key="'opt5'+i" v-model="step.custom[c.uuid]" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)")
                                            template(v-if="c.type == 4")
                                                v-select(clearable v-if="c.depends == null ? true : !c.depends.length" v-model="step.custom[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="c.required" @input="computedDepends(c, $event)")
                                                v-select(clearable v-else v-model="step.custom[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event)")
                                            v-radio-group(v-if="c.type == 3" v-model="step.custom[c.uuid]")
                                                label {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                                v-radio(v-for="(opt, i) in c.options" :key="'opt6'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")
                                template(v-if="initialData")
                                    v-divider.mt-4
                                    vue-recaptcha.full-width.d-flex.justify-center.my-2.text-xs-center(v-if="Object.keys(currentNetwork).length && currentNetwork.recaptcha" :sitekey="captchaKey" ref="recaptcha" @verify="recaptchaVerifiy")
                                    .btns.btns-center
                                        v-btn(v-if="terms" color="primary" large @click="save" :loading="loadingBtn") {{ $t('Registrarse') }}
                div(v-else)
                    h2.secondary--text {{ $t('La encuesta no existe o ha sido inhabilitada') }}
                    v-btn.d-block(color="primary" @click="$router.push({path:'/login'})") {{ $t('Ir al inicio') }}
            v-dialog(v-model="termsModal" max-width="600") 
                v-card
                    v-card-text
                        v-tabs(
                            v-model="termActive"
                            color="white"
                            outline
                            slider-color="primary"
                        )
                            v-tab(:key="0") {{$t('Términos y condiciones')}}
                            v-tab(:key="1") {{$t('Políticas de privacidad')}} 
                            v-tab-item(:key="0")
                                v-card.mt-2(flat v-html="currentNetwork.terms")
                            v-tab-item(:key="1")
                                v-card.mt-2(flat v-html="currentNetwork.priv")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="primary" @click="termsModal = false") {{$t('Salir')}} 
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import Vue from 'vue'
    import VueRecaptcha from 'vue-recaptcha'

    export default {
        mixins: [auth], 
        metaInfo (){
            return {
                title: this.currentSurvey.name ? ('Encuesta - ' + this.currentSurvey.name) : 'Encuesta'
            }
        },
        data() {
            return {
                register              : 0,
                modalBirthdate        : false,
                terms                 : false,
                termActive            : 0,
                termsModal            : false,
                e1                    : true,
                state                 : [],
                city                  : [],
                country               : [],
                zones                 : [],
                zonei                 : -1,
                step                  : {
                    names             : '',
                    surname           : '',
                    surname2          : '',
                    email             : '',
                    password          : '',
                    phone             : '',
                    countryid         : '',
                    stateid           : '',
                    cityid            : '',
                    gender            : '',
                    birthdate         : null,
                    code              : '',
                    ccode             : '',
                    networkid         : '',
                    zones             : [],
                    document          : '',
                    custom            : {},
                    
                    // Recaptcha
                    recaptcha: ''
                },
                surveyrid             : '',
                error                 : false,
                errorText             : '',
                loadCheck             : false,
                loadingBtn            : false,
                hasData               : false,
                initialData           : false,
                foundData             : {},
                customForm            : [],
                availableRow          : [],

                //Encuesta
                currentSurvey         : {},
                customSurvey          : [],
                preload               : true,
                isRegister            : false,
                survey                : {},
                loadSurvey            : false,
                loadSurveyWithRegister: false,
                loadSurveyWithoutRegister: false,
                finished              : false,
                code                  : '',
                alreadyFinished       : ''
            }
        },
        watch: {
            modalBirthdate (val) {
                val && this.$nextTick(() => (this.$refs.birthdate.activePicker = 'YEAR'))
            },
            terms(val){
                this.step.recaptcha = ''
            }
        },
        computed: {
            phone(){
                let country = this.country.find((item) => item.uuid == this.step.countryid)
                return country ? country.tel : ''
            },
            networkid(){
                if(this.step.networkid){
                    return this.step.networkid
                }

                return this.currentNetwork.uuid
            },
            surveyurl() {

                let url = `https://${this.currentNetwork.alias}.wappid.com`

                if (this.currentNetwork.custom_domain) {
                    url = `https://${this.currentNetwork.custom_domain}`
                }

                if(this.codeSurvey){
                    return `${url}/e/${this.currentSurvey.alias}?r=${this.codeSurvey}`
                }else{
                    return `${url}/e/${this.currentSurvey.alias}`
                }
            },
            isProfile(){
                return (this.profile.uuid ? true : false) || false
            },
            codeSurvey(){
                return this.profile.code || this.code
            },
            hasSurvey(){
                return this.currentSurvey.name != undefined
            },
            shareurl(){
                return this.currentSurvey.share && (this.currentSurvey.share != null && this.currentSurvey.share != '') ? config.uploadurl + this.currentSurvey.share : config.imgurl
            },
            uploadurl(){
                return config.uploadurl
            }
        },
        async mounted(){

            await this.saveCurrentQuery('auto_survey')
                
            await this.saveCurrentRefCode('r')
			await this.currentRefCode()

            await this.saveCurrentRefCode('c')
            await this.currentContactCode()
            
            this.preload = true

            await this.fetchSurvey()
            
            let hasSurvey = await this.$getItem('f_survey_' + this.currentSurvey.uuid)
            this.alreadyFinished = hasSurvey

            if(hasSurvey){
                this.finished = true
            }
            
            // Autocompletado de encuestas
            this.autoSurvey()

            setTimeout(() => {
                
                this.preload = false
                this.fetchCountry()
                this.fetchForm()

            }, 1)
		},
        methods: {

            recaptchaVerifiy(response){
                this.step.recaptcha = response
            },

            range(start, end) {
                let length = (Number(end)-Number(start))+1
                return Array.from({length}, (_, i) => Number(start) + i)
            },

            /**
             * Autocompletado de encuestas
             * 
             */
            async autoSurvey(){

                // Autocompletado de encuestas
                let autoSurvey = await this.$getItem('auto_survey')

                if(autoSurvey != undefined){

                    try{
                        
                        let auto = JSON.parse(window.decodeURIComponent(autoSurvey))
                        for(let a in auto){

                            let val   = auto[a]
                            let findC = this.customSurvey.find((c) => c.uuid == a)

                            if(this.survey.hasOwnProperty(a) && findC){
                                
                                // Selección multiple
                                if(findC.type == 2){
                                    this.survey[a] = [val]
                                }

                                // Selección
                                else if(findC.type == 4){
                                    
                                    let opts = findC.depends == null ? findC.options : findC.coptions
                                    let uuid = opts.find(o => o.option == val)
                                    
                                    uuid = uuid ? uuid.uuid : ''
                                    this.survey[a] = uuid
                                }

                                // Imagen
                                else if(findC.type == 6){

                                    if(findC.depends == null){

                                        let opts = findC.depends == null ? findC.options : findC.coptions
                                        let uuid = opts.find(o => o.option == val)
                                        
                                        if(uuid){
                                            uuid.active = true
                                        }
                                    }

                                } else {
                                    this.survey[a] = val
                                }
                            }
                        }

                    }catch(e){}
                }
            },

            toogleActive(el, options, option){

                if(!(el.multiple && !el.depends)){

                    options = options.map((opt) => {
                        if(opt.uuid != option.uuid){
                            opt.active = false
                        }
                        return opt
                    })
                }

                if ((el.multiple && !el.depends) && el.config.max > 0 && options.filter(o => o.active).length >= el.config.max) {
                    option.active = false
                } else {
                    option.active = option.active ? false : true   
                }
                
                // No permitido en opción multiple
                if(el.multiple && el.depends){
                    this.computedDepends(el, {}, 'customSurvey')
                    return
                }

                if(!el.multiple && option.active){
                    this.computedDepends(el, option, 'customSurvey')
                }else{
                    this.computedDepends(el, {}, 'customSurvey')
                }

                if(el.multiple){

                    if(!Array.isArray(this.survey[el.uuid])){
                        this.survey[el.uuid] = []
                    }

                    if(option.active){
                        this.survey[el.uuid].push(option.option)
                    }else{
                        let idx = this.survey[el.uuid].findIndex((f) => f == option.option)
                        this.survey[el.uuid].splice(idx, 1)
                    }

                }else{

                    this.survey[el.uuid] = ''
                    if(option.active){
                        this.survey[el.uuid] = option.option
                    }
                }
            },

            selectedCountry(countryid){
                return this.country.find(c => c.uuid == countryid)
            },

            fetchCountry(){

                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            ab: 1, 
                            networkid: this.networkid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.step.countryid,
                                ab: 1, 
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.state = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchCity(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.step.stateid,
                                ab: 1, 
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.city = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchZone(item, push_empty_item = true){

                this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei + 1)
                this.step.zones = this.zonei == -1 ? [] : this.step.zones.slice(0, this.zonei+1)
                
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid: this.step.cityid,
                                parent: this.zonei == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if(data.data.length){

                                    if (push_empty_item) {
                                        this.step.zones.push('')    
                                    }

                                    this.zones.push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchSurvey() {

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/survey/register', {
                            params: {
                                networkid : this.networkid,
                                survey    : this.$route.params.survey ?? this.currentNetwork.proxiedSurvey
                            }
                        }).then((r) => {

                            let data = r.data
                            if (data.response) {

                                this.currentSurvey = data.response ? data.data.survey : {}
                                this.customSurvey  = data.data.custom
                                this.finished = data.data.has

                                for(let custom of this.customSurvey){
                                    if(custom.type == 2){
                                        Vue.set(this.survey, custom.uuid, [])
                                    }else{
                                        Vue.set(this.survey, custom.uuid, '')
                                    }
                                }
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchForm() {
                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: {
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {

                            let custom = data.data.custom.map((c) => {
                                c.required   = c.required == 1 ? true : false
                                c.visibility = c.visibility == 1 ? true : false
                                return c
                            })
                            this.customForm = custom
                            this.availableRow = data.data.available == null ? [] : data.data.available

                            for(let custom of this.customForm){
                                if(custom.type == 2){
                                    Vue.set(this.step.custom, custom.uuid, [])
                                }else{
                                    Vue.set(this.step.custom, custom.uuid, '')
                                }
                            }
                        }
                    })
                })
            },

            check(){

                if(!this.terms){
                    return
                }

                let post = this._.cloneDeep(this.step)
                post.surveyid  = this.currentSurvey.uuid
                post.surveyrid = this.surveyrid

                this.loadCheck = true

                this.$api(this, (xhr) => {
                    xhr.post('/register/check', this.$qs.stringify(post)).then(async (r) => {

                        let data = r.data
                        
                        if(!data.response){

                            this.loadCheck = false
                            if(data.message.length){
                                this.error = true
                                this.errorText = data.message
                            }else{
                                this.hasData = false
                                this.initialData = true
                            }
                        }else{

                            if(data.data.token){

                                this.$setItem('xtoken', data.data.token, () => {
                                    this.code       = data.data.code
                                    this.finished   = true
                                    this.isRegister = false
                                })

                                return
                            }
                            
                            data = data.data
                            this.hasData   = true
                            this.foundData = data

                            this.foundData.birthdate = this.$moment(this.foundData.birthdate).format('YYYY-MM-DD')

                            this.step = this._.merge(this.step, {
                                names    : this.foundData.names.toString().trim() + ' ' + this.foundData.surname.toString().trim() + ' ' + this.foundData.surname2.toString().trim(),
                                countryid: this.foundData.countryid,
                                stateid  : this.foundData.stateid,
                                cityid   : this.foundData.cityid,
                                birthdate: this.foundData.birthdate,
                                document : this.foundData.document,
                                gender   : this.foundData.gender,
                                phone    : this.foundData.phone 
                            })

                            await this.fetchState()
                            await this.fetchCity()
                            await this.fetchZone()

                            if(this.foundData.zones.length){

                                let zone  = this.foundData.zones[0]
                                let zones = this.foundData.zones.slice(1, this.foundData.zones.length)
                                this.step.zones.splice(0, 1, zone)

                                if(!zones.length){

                                    this.zonei = 0
                                    await this.fetchZone(zone)
                                }

                                let zlen = zones.length
                                for(let i = 1, j=0; i <= zlen; i++, j++){

                                    let parent = this.step.zones.slice(-1)[0]
                                    this.zonei = i

                                    this.step.zones.push(zones[j])

                                    let push_empty_item = zlen == i ? false : true
                                    await this.fetchZone(parent, push_empty_item)
                                }
                            }

                            this.loadCheck = false
                            this.initialData = true
                        }

                    }).catch(() => {
                        this.loadCheck = false
                        this.hasData   = false
                    })
                })
            },

            save(){

                if(!this.terms){
                    return
                }

                let post = this._.cloneDeep(this.step)
                post.zones = JSON.stringify(post.zones)

                if(this.hasData){
                    post.birthdate = this.foundData.birthdate
                    post.cityid    = this.foundData.cityid
                    post.countryid = this.foundData.countryid
                    post.document  = this.foundData.document
                    post.gender    = this.foundData.gender
                    post.names     = this.foundData.names.toString().trim() + ' ' + this.foundData.surname.toString().trim() + ' ' + this.foundData.surname2.toString().trim(),
                    post.phone     = this.foundData.phone
                    post.stateid   = this.foundData.stateid
                    post.zones     = this.foundData.zones != null ? this.foundData.zones.length ? this.foundData.zones : '[]' : '[]'
                }

                let custom = this._.cloneDeep(this.step.custom)

                for(let c in custom){
                    if(typeof custom[c] != 'string'){
                        custom[c] = Array.isArray(custom[c]) ? custom[c] : custom[c].option
                    }
                }

                post.custom    = JSON.stringify(custom)
                post.surveyid  = this.currentSurvey.uuid
                post.surveyrid = this.surveyrid

                this.loadingBtn = true
                this.loadCheck  = true

                this.$api(this, (xhr) => {
                    xhr.post('/register', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.loadCheck = false
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            
                            data = data.data
                            this.$setItem('xtoken', data.token, () => {
                                this.$router.push({path: '/home' })
                            })
                        }
                    }).catch(() => {})
                })
            },

            saveSurvey(isRegister, loader, confirm=false){

                let survey = this._.cloneDeep(this.survey)
                for(let c in survey){
                    if(typeof survey[c] != 'string' && typeof survey[c] != 'number'){
                        survey[c] = Array.isArray(survey[c]) ? survey[c] : survey[c].option
                    }
                }

                let post = {
                    survey    : JSON.stringify(survey),
                    surveyid  : this.currentSurvey.uuid,
                    networkid : this.networkid,
                    f_survey  : this.alreadyFinished,
                    ccode     : this.step.ccode,
                    recaptcha : this.step.recaptcha,
                    wpconfirm : confirm ? 1 : 0
                }

                this.loadSurvey = true
                this[loader] = true

                this.$api(this, (xhr) => {
                    xhr.post('/survey/register', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadSurvey = false
                        this[loader] = false
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{

                            this.$setItem('f_survey_' + this.currentSurvey.uuid, data.data, async () => {
                                
                                await this.$setItem('auto_survey')

                                // Redirección confirmar WhatsApp
                                if(confirm){

                                    if(!this.isMobile()){
                                        window.open(data.wpconfirm, '_blank')
                                    }else{
                                        window.location.href = data.wpconfirm
                                    }
                                }

                                this.finished = true
                                this.surveyrid = data.data

                                if(isRegister){
                                    this.isRegister = true
                                }
                            })
                        }

                    }).catch(() => {
                        this.loadSurvey = false
                        this[loader] = false
                    })
                })
            },

			async currentRefCode(){
				this.step.code = await this.$getItem('r')
			},

            async currentContactCode(){
				this.step.ccode = await this.$getItem('c')
			},

            isAvailable(tag){
                
                if(!this.availableRow.length){
                    return true
                }

                let row = this.availableRow.find((r) => r.tag == tag)

                if(!row){
                    return true
                }

                return row.enable == 1 ? true : false
            },

            isRequired(tag) {
                if(!this.availableRow.length){
                    return true
                }

                let row = this.availableRow.find((r) => r.tag == tag)

                if(!row){
                    return true
                }

                return row.hasOwnProperty('required') ? (row.required == 1 ? true : false) : true
            },

            computedDepends(el, parent, model = 'customForm'){

                let depends = this[model].filter((c) => {
                    return c.hasOwnProperty('depends') && c.depends == el.uuid
                })

                for(let depend of depends){

                    let index = this[model].findIndex((c) => c.uuid == depend.uuid)
                    if(parent != null && depend.options.hasOwnProperty(parent.uuid)){

                        let options = depend.options[parent.uuid].map((option) => {
                            option.active = false
                            return option
                        })

                        Vue.set(this[model][index], 'coptions', depend.options[parent.uuid])
                    }else{
                        Vue.set(this[model][index], 'coptions', [])
                    }
                }
            },

            shareFb(uuid){
                window.open(
                    'https://www.facebook.com/sharer/sharer.php?u=' + window.encodeURIComponent(this.surveyurl), 
                    'popup', 
                    'width=400, height=300'
                )
            },

            shareTw(uuid){
                window.open(
                    'https://twitter.com/intent/tweet?text=' + window.encodeURIComponent(this.currentSurvey.name + ' ' + this.surveyurl), 
                    'popup',
                    'width=400, height=300'
                )
            },
            
            shareWp(uuid){
                let url = this.isMobile() ? 'whatsapp://' : 'https://api.whatsapp.com/'
                window.open(
                    url+'send?text=' + window.encodeURIComponent(this.currentSurvey.name + ' ' + this.surveyurl),
                    'popup'
                )
            },

            // Repetir encuesta
            repeatSurvey(){

                this.$removeItem('f_survey_' + this.currentSurvey.uuid, () => {
                    window.location.href = this.surveyurl
                });
            },

            // Validar seleccion multiple
            limitMutipleSelection(model, el) {
                if (el.type == 2) {
                    if (el.config.max > 0 && model.length > el.config.max) {
                        model.pop()
                    }
                }
            }
        },
        components: {
            VueRecaptcha
        }
    }

</script>